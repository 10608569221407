import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";

function JoinGame() {
  const { gameId } = useParams();
  const { activeDeck } = useContext(AppContext);
  const { currentUser, currentPlayer, setCurrentGame, setCurrentPlayer } =
    useContext(AuthContext);

  const handleJoin = async () => {
    let game = await activeDeck.games.update(gameId, {
      game: {
        players_attributes: [
          {
            name: currentUser.name,
            user_id: currentUser.id,
            chips_attributes: [
              {
                value: 1000,
                holder_type: "Player",
                holder_id: currentPlayer?.id,
                user_id: currentUser.id,
              },
            ],
          },
        ],
      },
    });
    setCurrentGame(game);
    setCurrentPlayer(
      game?.players &&
        game.players.find((p) => {
          return p.user_id === currentUser.id;
        })
    );
  };

  const handleLeave = async () => {
    await activeDeck.games.update(gameId, {
      game: {
        players_attributes: [
          {
            id: currentPlayer?.id,
            _destroy: true,
          },
        ],
      },
    });
    setCurrentPlayer(null);
    setCurrentGame(null);
  };

  if (currentPlayer) {
    return (
      <button
        className="btn-primary absolute left-6 top-5"
        onClick={handleLeave}
      >
        Leave Game
      </button>
    );
  } else {
    return (
      <button
        className="btn-primary  absolute left-6 top-5"
        onClick={handleJoin}
      >
        Join Game
      </button>
    );
  }
}

export default JoinGame;
