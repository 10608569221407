import React, { useState, createContext, useContext } from "react";
import ActiveDeck from "active_deck";
import { AuthContext } from "./AuthContext";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [games, setGames] = useState([]);

  const { token } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [deck, setDeck] = useState(null);
  const [notice, setNotice] = useState(null);
  const activeDeck = new ActiveDeck(token, process.env.REACT_APP_API_URL);

  async function resetDeck(deckId) {
    setLoading(true);
    let deck = await activeDeck.decks.setup(deckId);
    setDeck(deck);
    setLoading(false);
  }

  async function fetchDeck(id) {
    const deck = await activeDeck.decks.fetch(id);
    setDeck(deck);
    return deck;
  }

  async function fetchGames(gameType) {
    setLoading(true);
    let res = await activeDeck.games.fetchAll(gameType);
    if (res?.length === 0) {
      let newGame = await activeDeck.games.create({
        game: {
          decks: 1,
          game_type: gameType,
        },
      });
      console.log(newGame)
      res = await activeDeck.games.fetchAll(newGame?.game_type);
    }
    setGames(res);
    setLoading(false);
  }

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        activeDeck,
        deck,
        resetDeck,
        notice,
        setNotice,
        setDeck,
        fetchDeck,
        games,
        setGames,
        fetchGames,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
