import React, { useState, useEffect, createContext } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    let userString = localStorage.getItem("active-deck-user");
    let user = JSON.parse(userString);
    if (user) {
      setToken(user.token);
    }

    async function fetchCurrentUser() {
      try {
        let response = await fetch(`${process.env.REACT_APP_API_URL}me`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        let user = await response.json();
        setCurrentUser(user);
        if (user.games.length > 0) {
          setCurrentGame(user.games[0]);
          let p = user.games[0].players.find((p) => p.user_id === user.id);
          setCurrentPlayer(p);
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (token && !currentUser) {
      fetchCurrentUser();
    }
  }, [currentUser, token]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        token,
        setToken,
        currentGame,
        setCurrentGame,
        currentPlayer,
        setCurrentPlayer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
