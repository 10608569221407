import { useEffect, useContext, useCallback } from "react";

import { AuthContext } from "../../context/AuthContext";
import BlackjackTable from "./BlackjackTable";
import WarTable from "./WarTable";

function Tables() {
  const { currentGame } = useContext(AuthContext);
  const renderTable = useCallback(() => {
    switch (currentGame?.game_type) {
      case "blackjack":
        return <BlackjackTable />;
      case "war":
        return <WarTable />;
      default:
        return "No game selected";
    }
  }, [currentGame]);

  useEffect(() => {
    renderTable();
  }, [renderTable]);

  return <div>{renderTable()}</div>;
}

export default Tables;
