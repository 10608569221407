import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function Home() {
  const { currentGame } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentGame) {
      navigate(`/games/${currentGame?.game_type}/${currentGame.id}`);
    }
  }, [currentGame, navigate]);

  return (
    <div className="flex flex-col w-full text-white items-center justify-around text-4xl font-bold">
      <div>ActiveDeck</div>
      <img
        src="https://lbp.nyc3.cdn.digitaloceanspaces.com/cards/facedown.png"
        alt="Back of card"
        className="h-44 mt-10"
      />
    </div>
  );
}

export default Home;
