import { useContext } from "react";
import Card from "../Decks/Card";
import { AuthContext } from "../../context/AuthContext";

function Dealer() {
  const { currentGame } = useContext(AuthContext);

  return (
    <div
      key={currentGame.dealer.id}
      className="m-5 rounded-full px-10 py-10 flex justify-center items-center flex-col bg-gray-900 text-gray-50 mt-16 mb-0 max-w-xs mx-auto"
    >
      <div>{currentGame.dealer.name}</div>
      <div className="flex items-center w-full justify-center">
        {currentGame.dealer.cards.map((card) => {
          return <Card key={card.id} card={card} cssClasses="h-28" />;
        })}
      </div>
      {currentGame.dealer.hand_value && (
        <div className="text-2xl font-bold">
          {currentGame.dealer.hand_value}
        </div>
      )}
    </div>
  );
}

export default Dealer;
