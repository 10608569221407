import { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";

function Games() {
  const [gameTypes] = useState([
    {
      name: "War",
      path: "/games/war",
    },
    {
      name: "Blackjack",
      path: "/games/blackjack",
    },
  ]);

  return (
    <div>
      <div className="flex flex-col w-full container mx-auto justify-center items-center">
        <h1 className="text-2xl font-bold">Games</h1>
        <ul>
          {gameTypes.map((gameType) => {
            return (
              <li key={gameType.path}>
                <Link to={gameType.path}>{gameType.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Games;
