import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { NavLink, Link } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const { currentUser, token, setCurrentUser, setToken, currentPlayer } =
    useContext(AuthContext);
  const links = [
    {
      name: "Games",
      path: "/games",
    },
  ];
  if (!currentUser) {
    links.push({
      name: "Sign In",
      path: "/users/sign_in",
    });
    links.push({
      name: "Sign Up",
      path: "/users/sign_up",
    });
  }
  async function handleSignOut() {
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}auth`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        alert("Invalid credentials");
        return;
      }
      setCurrentUser(null);
      setToken(null);
      localStorage.removeItem("active-deck-user");
      navigate("/users/sign_in");
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <nav className="bg-green-700">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Link to={"/"} className="text-2xl font-bold text-gray-50">
                ActiveDeck
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {links.map((link, index) => (
                  <NavLink
                    key={index}
                    to={link.path}
                    className="text-white px-3 py-2 text-sm font-medium"
                    aria-current="page"
                  >
                    {link.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div>{currentPlayer?.formatted_balance}</div>
          {currentUser && (
            <div
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleSignOut}
              className="text-white rounded-md px-3 py-2 text-sm font-medium hover:bg-green-600 hover:text-white hover:cursor-pointer"
            >
              Sign out {currentUser.name || currentUser.email}
            </div>
          )}
        </div>
      </div>

      <div className="sm:hidden" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {links.map((link, index) => (
            <NavLink
              key={index}
              to={link.path}
              className={`text-white rounded-md px-3 py-2 text-sm font-medium ${
                index === 0 ? "bg-green-900" : ""
              }`}
              aria-current="page"
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
