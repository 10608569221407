import { useContext } from "react";

function PlayGame({ currentGame }) {
  async function handlePlay() {
    if (currentGame?.started) {
      console.log("started");
    } else {
      console.log("not started");
    }
  }
  return (
    <button className="btn-primary absolute top-16 left-5" onClick={handlePlay}>
      Play
    </button>
  );
}

export default PlayGame;
