import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
// import cookies from "js-cookie";
const PrivateRoute = ({ children }) => {
  const { token } = useContext(AuthContext);
  const location = useLocation();

  return token ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/users/sign_in"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};

export default PrivateRoute;
