import Dealer from "../Players/Dealer";

function BlackjackTable() {
  return (
    <>
      <Dealer />
      <div className="flex justify-center w-full">
        <div className="oval mt-16"></div>
      </div>
    </>
  );
}

export default BlackjackTable;
