import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Loading from "../layout/Loading";
import FacedownCard from "./FacedownCard";

function Draw() {
  const { loading, deck } = useContext(AppContext);

  return loading ? <Loading /> : <FacedownCard deck={deck} />;
}

export default Draw;
