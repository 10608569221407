import "./Game.css";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { capitalize } from "../../helpers/stringHelper";
import { createConsumer } from "@rails/actioncable";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import JoinGame from "../../components/Games/JoinGame";
import PlayGame from "../../components/Games/PlayGame";
import Players from "../../components/Players";
import Tables from "../../components/Tables";

function Game() {
  const [cable] = useState(createConsumer(process.env.REACT_APP_WEBSOCKET_URL));
  const { gameId, gameType } = useParams();
  const { activeDeck, loading, setLoading, setNotice } = useContext(AppContext);
  const { currentGame, setCurrentGame, setCurrentUser } =
    useContext(AuthContext);

  useEffect(() => {
    if (!gameId) return;

    cable.subscriptions.create(
      {
        channel: `GameChannel`,
        game_id: gameId,
      },
      {
        connected: () => {
          // console.log("connected");
        },
        disconnected: (data) => {
          setNotice(data.message);
        },
        received: (data) => {
          if (data.message) {
            setNotice(data.message);
          }
          if (data.user) {
            setCurrentUser(JSON.parse(data.user));
          }
        },
      }
    );
  }, [cable.subscriptions, gameId, setNotice, setCurrentUser]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const currentGame = await activeDeck.games.fetch(gameId);
      setCurrentGame(currentGame);

      setLoading(false);
    }
    if (currentGame === null && loading === false) {
      fetchData();
    }
  }, [activeDeck, gameId, setLoading, currentGame, setCurrentGame, loading]);

  return (
    <div className="flex flex-col items-center h-full w-full justify-around relative">
      <div className="text-3xl text-center absolute top-5">
        {capitalize(gameType)}
      </div>
      <JoinGame />
      <PlayGame currentGame={currentGame} />
      <Tables currentGame={currentGame} />
      <Players />
    </div>
  );
}

export default Game;
