import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

function Notice() {
  const { notice, setNotice } = useContext(AppContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setNotice(null);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [notice, setNotice]);

  return (
    notice && (
      <div className="absolute rounded-md bg-blue-50 p-4 max-w-lg right-5 top-20 z-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-blue-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              {notice ? notice : "No notice at this time"}
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export default Notice;
