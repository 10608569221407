import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createConsumer } from "@rails/actioncable";

import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import Draw from "../../components/Decks/Draw";
import DrawnCards from "../../components/Decks/DrawnCards";
import ButtonGroup from "../../components/Decks/ButtonGroup";

function Deck() {
  const [cable] = useState(createConsumer(process.env.REACT_APP_WEBSOCKET_URL));
  const { fetchDeck, deck, setNotice, setDeck } = useContext(AppContext);
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    cable.subscriptions.create(
      {
        channel: `DeckChannel`,
        deck_id: id,
      },
      {
        connected: () => {
          // console.log("connected");
        },
        disconnected: (data) => {
          setNotice(data.message);
        },
        received: (data) => {
          setNotice(data.message);
          if (data.deck) {
            setDeck(data.deck);
          }
        },
      }
    );
  }, [cable.subscriptions, id, setNotice, setDeck]);

  useEffect(() => {
    async function fetchData() {
      let res = await fetchDeck(id);
      if (res.error) {
        navigate("/decks");
        setNotice(res.error);
      }
    }

    if (!token) return;
    if (deck?.id === parseInt(id)) return;

    fetchData();
  }, [deck, fetchDeck, id, token, navigate, setNotice]);

  return (
    <>
      <div className="flex justify-center p-4">
        <ButtonGroup />
      </div>
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mx-auto justify-items-center py-10 min-h-screen h-full bg-green-600">
        <Draw />
        <DrawnCards deck={deck} />
      </div>
    </>
  );
}

export default Deck;
