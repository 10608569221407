import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import LinkToDeck from "../../components/Decks/LinkToDeck";

function Decks() {
  const [decks, setDecks] = useState([]);

  const { setLoading, activeDeck } = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) return;
    if (decks?.length > 0 || !decks) return;
    async function fetchData() {
      setLoading(true);
      try {
        let res = await activeDeck.decks.fetchAll();
        setDecks(res);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
    fetchData();
  }, [activeDeck.decks, decks, setDecks, setLoading, currentUser]);

  return (
    <div className="container py-10 min-h-screen h-full bg-green-600 mx-auto">
      <div className="flex flex-col items-center justify-center w-full">
        <h1 className="text-4xl font-bold text-gray-50">Decks</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6 mx-auto justify-items-center mx-auto w-full">
          {decks?.length > 0 ? (
            decks.map((deck) => (
              <div key={deck.id} className="my-4">
                <LinkToDeck deck={deck} />
              </div>
            ))
          ) : (
            <h2 className="text-2xl font-bold text-gray-50">
              No decks found. Create one!
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default Decks;
