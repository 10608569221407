import { useContext } from "react";
import "./Player.css";
import Player from "./Player";

import { AuthContext } from "../../context/AuthContext";

function Players() {
  const { currentGame } = useContext(AuthContext);
  return (
    currentGame?.players && (
      <ul className="flex items-center w-full justify-around">
        {currentGame?.players.map((player) => (
          <Player key={player.id} player={player} />
        ))}
      </ul>
    )
  );
}

export default Players;
