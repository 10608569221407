import Card from "../Decks/Card";

function Player({ player }) {
  return (
    <li
      key={player.id}
      className="m-5 rounded-full px-10 py-10 flex justify-center items-center flex-col bg-gray-900 text-gray-50"
    >
      <div>{player.name}</div>
      <div>{player.formatted_balance}</div>
      <div className="flex items-center w-full">
        {player.cards.map((card) => {
          return <Card key={card.id} card={card} cssClasses="h-28" />;
        })}
      </div>

      {player.hand_value && (
        <div className="text-2xl font-bold">{player.hand_value}</div>
      )}
    </li>
  );
}

export default Player;
