import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

function FacedownCard({ deck, height = "h-44" }) {
  const { activeDeck, setLoading } = useContext(AppContext);

  async function draw(deckId, opts = {}) {
    if (deck?.cards?.length === 0) {
      return;
    }
    setLoading(true);
    await activeDeck.decks.draw(deckId, {
      ...opts,
    });
    setLoading(false);
  }
  return (
    <img
      src="https://lbp.nyc3.cdn.digitaloceanspaces.com/cards/facedown.png"
      alt="Back of card"
      className={`${height} cursor-pointer`}
      onClick={() => {
        draw(deck?.id, { count: 1 });
      }}
    />
  );
}

export default FacedownCard;
