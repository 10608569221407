import { useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { AppContext } from "../../context/AppContext";
// import Loading from "../../components/Loading";

function Game() {
  const { gameType } = useParams();
  const { activeDeck, loading, setLoading, fetchGames, games, setGames } =
    useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      await fetchGames(gameType);
    }

    if (gameType !== games[0]?.game_type && loading === false) {
      fetchData();
    }
  }, [games, activeDeck, setLoading, loading, fetchGames, gameType, setGames]);
  return (
    <div className="flex flex-col w-full container mx-auto justify-center items-center">
      <ul className="flex flex-col items-center">
        {gameType}

        {games?.map((game) => {
          return (
            <li key={game.id}>
              <Link to={`/games/${gameType}/${game.id}`}>
                {game.game_type} {game.id}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Game;
