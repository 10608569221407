import { Routes, Route } from "react-router-dom";

import "./App.css";

import { AppContextProvider } from "./context/AppContext";
import Navbar from "./components/layout/Navbar";
import Notice from "./components/layout/Notice";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home/";
import Decks from "./pages/Decks/Decks";
import Games from "./pages/Games/";
import GameList from "./pages/Games/GameList";
import Game from "./pages/Games/Game";
import Deck from "./pages/Decks/Deck";
import SignIn from "./pages/SignIn/";
import SignUp from "./pages/SignUp/";
import NotFound from "./pages/NotFound/";
import PrivateRoute from "./components/auth/PrivateRoute";

function App() {
  return (
    <AppContextProvider>
      <div className="bg-green-600 flex flex-col justify-between min-h-screen">
        <Navbar />
        <Notice />
        <Routes>
          <Route path="" element={<Home />} />
          <Route
            path="games/"
            element={
              <PrivateRoute>
                <Games />
              </PrivateRoute>
            }
          />
          <Route
            path="games/:gameType"
            element={
              <PrivateRoute>
                <GameList />
              </PrivateRoute>
            }
          />
          <Route
            path="games/:gameType/:gameId"
            element={
              <PrivateRoute>
                <Game />
              </PrivateRoute>
            }
          />
          <Route
            path="decks/"
            element={
              <PrivateRoute>
                <Decks />
              </PrivateRoute>
            }
          />
          <Route
            path="decks/:id"
            element={
              <PrivateRoute>
                <Deck />
              </PrivateRoute>
            }
          />

          <Route path="users/sign_in" element={<SignIn />} />
          <Route path="users/sign_up" element={<SignUp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </AppContextProvider>
  );
}

export default App;
