import { Link } from "react-router-dom";

function LinkToDeck({ deck, draw }) {
  return (
    <Link to={`/decks/${deck?.id}`}>
      <img
        src="https://lbp.nyc3.cdn.digitaloceanspaces.com/cards/facedown.png"
        alt="Back of card"
        className="h-44 cursor-pointer"
      />
    </Link>
  );
}

export default LinkToDeck;
