import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function ButtonGroup() {
  const { deck, resetDeck } = useContext(AppContext);

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        onClick={() => resetDeck(deck?.id)}
      >
        Shuffle
      </button>
      <button
        type="button"
        className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        {deck && deck.cards?.length} cards left
      </button>
    </span>
  );
}
