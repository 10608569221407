import Card from "./Card";

export default function Cards({ deck }) {
  return (
    <>
      {deck?.drawn_cards?.length > 0 &&
        deck.drawn_cards
          .sort((a, b) => b.position - a.position)
          .map((card) => {
            return <Card card={card} key={card.id} />;
          })}
    </>
  );
}
